@import "src/styles/themes";
@import "src/styles/functions";

$scrollerContentHeight: 20vh;

.horizontalScroller {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
    align-items: flex-end;
    overflow-x: auto;
    overscroll-behavior-x: contain;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    gap: 10px;

    > * {
        flex: 0 0 auto;
    }
}

.spinnerBox {
    display: flex;
    flex-direction: row;
    height: $scrollerContentHeight;
    align-items: center;
    justify-content: center;
}

.itemContainer {
    display: flex;
    flex-direction: column;
    height: fit-content;
    max-height: 95vh;
    padding-bottom: max(0px, env(safe-area-inset-bottom));

    background-color: $background-color-light;
    color: $primary-text-color-light;

    .chevronIconContainer {
        transform: scaleY(0.5);
    }

    .chevronIcon {
        width: 10px;
        height: 10px;
        margin: 5px auto 0px auto;
        border-top: 3px solid #626161;
        border-right: 3px solid #626161;
        transform: rotate(135deg);
    }

    .subscriptionProgramImage {
        margin: 5px auto;
        height: min(1rem, 40px);
        max-width: calc(100% - 20px);
        padding: 0 10px;
    }
    .subscriptionProgramImage.rplLogo {
        height: min(1.5rem, 40px);
    }

    .header {
        margin: 10px auto;
        padding: 0 12px;
        @media screen and (min-width: $tabletBreakpointW) {
            padding-left: 24px;
            padding-right: 24px;
        }
        text-align: center;
        font-weight: bold;
    }

    .headerDescription {
        padding: 0 12px;
        @media screen and (min-width: $tabletBreakpointW) {
            padding-left: 24px;
            padding-right: 24px;
        }
        margin-bottom: 10px;
    }

    .bookCover {
        background-color: black;
        line-height: 0%;
        position: relative;
    }

    .bookCover > img {
        height: $scrollerContentHeight;
    }

    .checkMarkContainer {
        position: absolute;
        top: calc(50% - 10px);
        width: 100%;
    }

    .checkMarkIcon {
        width: 20px;
        height: 10px;
        margin: 0px auto;
        border-top: 2px solid white;
        border-right: 2px solid white;
        transform: rotate(135deg);
    }

    .selectedBook {
        opacity: 0.4;
    }

    .main {
        flex: 1;
        padding-left: 12px;
        padding-right: 12px;
        @media screen and (min-width: $tabletBreakpointW) {
            padding-left: 24px;
            padding-right: 24px;
        }
    }

    .footer {
        display: flex;
        flex-direction: row;
        padding: 12px;
        @media screen and (min-width: $tabletBreakpointW) {
            padding-left: 24px;
            padding-right: 24px;
        }
        gap: 12px;
        button {
            flex-grow: 1;
            border: none;
            padding: 10px 12px;
            margin: 0;
            text-align: center;
            white-space: nowrap;
            color: $button-text-color-light;
            background-color: $button-background-color-light;
        }
        button.ctaButton {
            color: $button-text-color-cta;
            background-color: $button-background-color-cta;
        }
        button.ctaButton.disabledButton {
            opacity: 0.35;
        }
    }
}

.dark.itemContainer {
    background-color: $background-color-dark;
    color: $primary-text-color-dark;
    border: 1px solid #5a5a5a;
    border-bottom: 0;
}
