@import "src/styles/themes";
@import "src/styles/mixins";
@import "src/styles/functions";
@import "src/styles/global";

.coverShadow {
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: 4px;
    line-height: 0px;

    img {
        box-shadow: -4px 0px 4px 0px rgba(0, 0, 0, 0.25);
    }
}

.book.coverShadow {
    height: auto;
    line-height: 0px;
}

.book.coverShadow.vella {
    margin-top: 0;
}

.book.vella {
    aspect-ratio: 1;
    border-radius: 100%;
    overflow: hidden;
    .bookCover {
        width: 100%;
        object-position: 50% 16.5%;
        object-fit: cover;
        transform: scale(1.1034482759);
    }
}

.book {
    position: relative;
    display: block;
    height: 100%;
    // width: 100%;
    line-height: 0;

    .bookCover, .titleAndAuthor {
        opacity: 1;

        &.hidden {
            position: absolute;
            opacity: 0;
        }
    }

    .bookCover {
        object-fit: contain;
    }

    img.bookCover {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &.transition {
        .bookCover, .titleAndAuthor {
            transition: opacity ease 0.5s;
        }
    }

    .placeHolder {
        div {
            line-height: 1rem !important;
        }
        .titleAndAuthor {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            text-align: center;
            word-break: break-word;
            justify-content: center;
            padding: 5px;
        }

        // Leave room for the image's dark book icon
        .spacer {
            height: 20%;
        }

        .title {
            color: black;
            font-size: min(px-to-rem(12), $largeScreenMaxFontPx);
            @media screen and (max-width: $mediumScreenWidthBreakpoint) { font-size: min(px-to-rem(12), $mediumScreenMaxFontPx); }
            @media screen and (max-width: $smallScreenWidthBreakpoint) { font-size: min(px-to-rem(12), $smallScreenMaxFontPx); }
            @include truncateLines(3);
        }

        .author {
            padding-top: 5px;
            color: black;
            font-size: min(px-to-rem(10), 28px);
            @include truncateLines(2);
        }
    }
} 
