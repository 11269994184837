@import "src/styles/themes";

.content {
    padding-top: 10px;
    padding-bottom: 12px;
    transition: max-height ease 0.25s;

    // Enable hidden overflow and minimal mask by default so that they exist while animating expand/collapse
    // The mask is given a height equal to the padding-bottom so it isn't visible after animation finishes
    overflow: hidden;
    mask-image: linear-gradient(0deg, transparent, #000 12px);
    
    &.truncate.wouldTruncate {
        mask-image: linear-gradient(0deg, transparent, #000 3em);
        padding-bottom: 0px;
    }
}

.readMoreButton {
    display: flex;
    flex-direction: row;
    color: $link-text-light;
    padding-bottom: 9px;

    .downArrow {
        box-sizing: border-box;
        height: max(0.5em, 8px);
        width: max(0.5em, 8px);
        border: solid $link-text-light;
        border-width: 0 2px 2px 0;
        margin: 0.25em 0 0 max(0.25em, 5px);
        transform: rotate(45deg);
    }

    .upArrow {
        box-sizing: border-box;
        height: max(0.5em, 8px);
        width: max(0.5em, 8px);
        border: solid $link-text-light;
        border-width: 2px 0 0 2px;
        margin: 0.5em 0 0 max(0.25em, 5px);
        transform: rotate(45deg);
    }
}

.dark.readMoreButton {
    color: $link-text-dark;
    .downArrow {
        border: solid $link-text-dark;
        border-width: 0 2px 2px 0;
    }
    .upArrow {
        border: solid $link-text-dark;
        border-width: 2px 0 0 2px;
    }
}
