@import "src/styles/themes";

$fadeInDurationMS: 250;
$fadeOutDurationMS: 500;

:export {
    fadeInDurationMS: $fadeInDurationMS;
    fadeOutDurationMS: $fadeOutDurationMS;
}

.toast {
    background-color: $background-color-light;
    color: $primary-text-color-light;
    position: absolute;
    margin: 10px 8px 0px 8px;
    padding: 8px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: fit-content;
    max-width: 80%;
    min-width: 180px;
    box-shadow: -3px 2px 5px #a19e9e;
    border-style: solid;
    border-width: .5px;
    border-radius: 15px;
    border-color: $outer-border-color-dark;
    z-index: 1;
}

.toastShown {
    opacity: 1;
    transition: opacity $fadeInDurationMS*1ms linear;
}
  
.toastHide {
    opacity: 0;
    transition: opacity $fadeOutDurationMS*1ms linear;
}

.message {
    text-align: center;
}

.dark.toast { 
    background-color: $background-color-dark;
    color: $primary-text-color-dark;
    box-shadow: -3px 2px 5px #2e2e2e;
}
