@import "src/styles/themes";

ul.vellaTags {
    margin: 0;
    padding: 0;
    li {
        list-style-type: none;
    }
}

.vellaTags {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-items: center;
    img {
        margin-left: 5px;
        margin-right: 5px;
    }
    .vellaTagButton {
        color: $link-text-light;
        padding: 3px 10px;
        border: 1px solid $link-text-light;
        border-radius: 1rem;
        margin-top: 10px;
        margin-right: 8px;
    }
}

table.detailsTable {
    padding-top: 10px;
    width: 100%;
    padding-bottom: 10px;
    .itemRow {
        vertical-align: top;
        width: 100%;
    }
    td {
        margin-top: 5px;
        word-wrap: break-word;
    }
    .itemLabel {
        margin-right: 5px;
        width: 50%;
    }
}

.vellaCategories {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .vellaCategoryButton {
        color: $link-text-light;
    }
    .vellaCategorySeparator {
        margin-left: 5px;
        margin-right: 5px;
    }
}

.dark {
    .vellaTags {
        .vellaTagButton {
            color: $link-text-dark;
        }
    }
    .vellaCategories {
        .vellaCategoryButton {
            color: $link-text-dark;
        }
    }
}
