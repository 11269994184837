@import "src/styles/themes";
@import "src/styles/functions";

.rplUpsell {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: max(10px, min(4px, px-to-rem(4)));
    margin-top: 10px;
    text-wrap: wrap;
    color: $secondary-text-color-light;
    img.rplIcon {
        height: max(30px, min(px-to-rem(33), 50px));
        line-height: 0;
    }
}

.rplUpsell.dark {
    color: $secondary-text-color-dark;
}
