@import "src/styles/themes";
@import "src/styles/functions";
@import "src/styles/global";

// TODO: Does this need to get doubled for larger screens?
$margin: 12px;

.label {
    font-weight: bold;
    font-size: min(px-to-rem(16), $largeScreenMaxFontPx);
    @media screen and (max-width: $mediumScreenWidthBreakpoint) { font-size: min(px-to-rem(16), $mediumScreenMaxFontPx); }
    @media screen and (max-width: $smallScreenWidthBreakpoint) { font-size: min(px-to-rem(16), $smallScreenMaxFontPx); }
    padding-top: 10px;
    padding-bottom: 5px;
}

.fixedHeight {
    min-height: max(20vh, 150px);
}

.horizontalScroller {
    mask-image: linear-gradient(90deg, transparent 0px, #000 $margin, #000 calc(100% - ($margin + 10px)), transparent 100%);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
    align-items: flex-end;
    overflow-x: auto;
    overscroll-behavior-x: contain;
    overflow-y: hidden;
    gap: 8px;
    margin: 0;
    margin-left: -$margin;
    margin-right: -$margin;
    margin-bottom: 8px;
    padding-left: $margin;

    // hide the scroll bar
    scrollbar-width: none; // For Firefox
    -ms-overflow-style: none; // For Internet Explorer and Edge
    // overscroll-behavior-y: auto; /* For Chrome */
    // For Chrome, Safari, and Opera
    &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
        display: none;
    }

    > * {
        flex: 0 0 auto;
    }

    .coverButton {
        background-color: $background-color-light;
    }

    .coverWithBadgeWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .programBadge, .programBadgeRpl {
        img {
            // max-height: min(px-to-rem(14), 28px);
            max-height: 12px;
            max-width: 100px;
        }
    }

    .bookImage {
        line-height: 0%;
        margin: 0;
        padding: 0;
        img {
            object-fit: contain;
            height: max(20vh, 150px);
            // TODO: Consider different cover heights for different screen sizes
            // @media screen and (height: $mediumScreenWidthBreakpoint) { height: max(20vh, 150px); }
            // @media screen and (height: $smallScreenWidthBreakpoint) { height: max(20vh, 150px); }
            width: auto;
        }
    }
    .bookImageWithSticker {
        img {
            height: calc(max(20vh, 150px) - 12px);
        }
    }

    .bookImage:last-child {
        padding-right: calc($margin + 10px);
    }

    li {
        list-style-type: none;
    }
}

.horizontalScroller.tagsCarousel {
    gap: 6px;
    li {
        padding: 2px 0;
    }
    .tagsPill {
        padding: 5px 10px;
        border: 1px solid #747678;
        border-radius: 4px;
        margin: 1px 0;
        background-color: $background-color-light;
        color: $primary-text-color-light;
    }
    .tagsPill.selected {
        padding: 4px 9px;
        border: 2px solid #0274E6;
        font-weight: bold;
        background-color: #E6F2FF;
    }
    li.tagsEndSpacer {
        width: $margin;
    }
}

.dark {
    .coverButton {
        background-color: $background-color-dark;
    }
    .horizontalScroller.tagsCarousel {
        .tagsPill {
            border: 1px solid #8E9194;
            background-color: $background-color-dark;
            color: $primary-text-color-dark;
        }
        .tagsPill.selected {
            border: 2px solid #0582FF;
            background-color: #254973;
        }
    }
}
