@import "src/styles/themes";

$bottomSheetStateTransitionDuration: 500;

:export {
    bottomSheetStateTransitionDuration: $bottomSheetStateTransitionDuration;
}

body {
    overscroll-behavior: none;
}

dialog {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    border: 0;
    padding: 0;
    margin: 0;
    overflow: hidden;
    background-color: transparent;
}

.bottomSheetContainer {
    .background {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        background-color: black;
        opacity: 0;
        transition: opacity $bottomSheetStateTransitionDuration*1ms linear;
    }

    .bottomSheet {
        top: 0;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        transform: translateY(100vh);
        transition-property: transform;
        transition-duration: $bottomSheetStateTransitionDuration*1ms;
        transition-timing-function: ease-out;
        touch-action: none;
        will-change: transform;

        &.iOS {
            // Setting position absolute is a performance optimization on iOS (removes the creation of a new layer), but also leads to a bug on some iOS phone types
            // https://issues.amazon.com/KINDLE-REACH-10545
            // position: absolute;
            will-change: unset;
        }

        .bottomSheetSizer {
            display: flex;
            flex-direction: column;
            height: 100vh;
        }
        
        .bottomSheetFiller {
            flex-grow: 1;
            align-self: stretch;
        }
        .bottomSheetWidthLimiter {
            display: flex;
            flex-direction: row;
            .widthSpacer {
                flex-grow: 1;
            }
            .widthContent {
                width: min(100vw, $tabletBreakpointW);
            }
        }
    }

    &.fullscreen {
        .background {
            opacity: 0.75;
        }

        .bottomSheet {
            transform: translateY(0);
        }
    }

    &.initialOpen {
        .bottomSheet {
            transition-timing-function: cubic-bezier(.28,.84,.42,1.05);
        }
    }
}
