@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/themes";

.label {
    line-height: min(px-to-rem(21), 42px);
    color: #555;
    @include truncateLines(2);
    word-break: break-word;
    font-size: min(px-to-rem(14), 28px);
    @media screen and (min-width: $tabletBreakpointW) {
        font-size: min(px-to-rem(15), 28px);
    }
    padding-bottom: 4px;
}

.label.dark {
    color: #ededed;
}
