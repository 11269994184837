@import "src/styles/themes";

.bookSeries {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    .coverImage {
        margin: 12px;
        margin-left: 0px;
    }

    .link {
        color: $link-text-light;
    }
}

.bookSeries.miniWidget {
    padding-top: 12px;
}

.dark.bookSeries {
    .link {
        color: $link-text-dark;
    }
}
