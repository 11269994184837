@import "src/styles/themes";
@import "src/styles/functions";
@import "src/styles/global";

.customerReviews {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    margin-bottom: 10px;
}

.label {
    color: $primary-text-color-light;
    font-weight: bold;
    font-size: min(px-to-rem(16), $largeScreenMaxFontPx);
    @media screen and (max-width: $mediumScreenWidthBreakpoint) { font-size: min(px-to-rem(16), $mediumScreenMaxFontPx); }
    @media screen and (max-width: $smallScreenWidthBreakpoint) { font-size: min(px-to-rem(16), $smallScreenMaxFontPx); }
    padding-top: 10px;
}

.seeAllReviewsLink {
    color: $link-text-light;
    text-align: center;
    padding-bottom: 12px;
    margin-bottom: 10px;
}

.dark.label {
    color: $primary-text-color-dark;
}

.dark.seeAllReviewsLink {
    color: $link-text-dark;
}
