@use "sass:math";
@import 'src/styles/themes';

$starAspectRatio: math.div(19, 18);

.starRatingBar {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;

    .stars {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin-bottom: 2px;
        width: 1rem*$starAspectRatio*5;
        max-width: 25px*$starAspectRatio*5;

        img {
            height: 1rem;
            max-height: 25px;
            width: 1rem*$starAspectRatio;
            max-width: 25px*$starAspectRatio;
        }
    }

    .reviewCount {
        color: $link-text-light;
        margin-left: 5px;
    }

    // screen reader only text
    .srOnly {
        display: none;
    }
}

.dark.starRatingBar {
    .reviewCount {
        color: $link-text-dark;
    }
}
