$background-color-light: white;
$background-color-dark: #1C1C1D;

$primary-text-color-light: black;
$primary-text-color-dark: white;

$secondary-text-color-light: #6C7778;
$secondary-text-color-dark: rgba(255, 255, 255, 0.67);

$link-text-light: #1E78BA;
$link-text-dark: #29ABE2;

$release-date-green-light: #008A00;
$release-date-green-dark: #47B120;

$read-now-button-background-color: #2578CC;
$read-now-button-text-color: white;

$button-background-color-light: #EAEAEA;
$button-background-color-dark: #2C2C2E;
$button-background-color-cta: #FCA50A;

$button-text-color-light: black;
$button-text-color-dark: white;
$button-text-color-cta: black;

$divider-background-color-light: #CCC;
$divider-background-color-dark: #333;

$outer-border-color-dark: #999999;

$tabletBreakpointW: 720px;
$tabletBreakpointH: 1250px;
