@use "sass:math";
@import "src/styles/themes";
@import "src/styles/functions";
@import "src/styles/mixins";
@import "src/styles/global";


.quickViewSlideHeader {

    .programBadge, .programBadgeRpl {
        display: flex;
        margin-top: 10px;
        img {
            max-height: min(px-to-rem(16), 28px);
            max-width: 100%;
        }
    }

    .programBadgeRpl {
        img {
            height: max(px-to-rem(24), 114px);
            max-height: max(min(px-to-rem(16), 28px), 20px);
        }
    }

    .bookDetailHeader {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;
    
        .coverImageContainer {
            flex-shrink: 0;

            div {
                line-height: 0;
                img {
                    max-width: min(40vw, 200px);
                    // Cap the height of the cover image based on the tallest acceptable aspect ratio (2:3 width:height)
                    max-height: min(35vh, 300px);
                }
            }
        }
        .vella.coverImageContainer {
            div {
                img {
                    max-height: min(40vw, 200px);
                    max-width: min(40vw, 200px);
                }
            }
        }

        .vellaIcons {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 10px;
            align-items: center;
            .vellaEpisodesIcon {
                display: flex;
                align-items: center;
                gap: 5px;
            }
            .vellaCompletedIcon {
                display: flex;
                align-items: center;
                gap: 5px;
            }
            .vellaLikesIcon {
                display: flex;
                align-items: center;
                gap: 5px;
            }
            .vellaTopFavedIcon {
                display: flex;
                align-items: center;
                gap: 5px;
                img {
                    width: min(max(1rem, 16px), 20px);
                    height: min(max(1rem, 16px), 20px);
                }
            }
        }

        .metadataContainer {
            display: flex;
            flex: 1 1 auto;
            flex-direction: column;
            flex-wrap: nowrap;
            margin-left: 12px;
            @media screen and (min-width: $tabletBreakpointW) {
                margin-left: 24px;
            }
            .title {
                font-weight: bold;
                font-size: min(px-to-rem(16), $largeScreenMaxFontPx);
                @media screen and (max-width: $mediumScreenWidthBreakpoint) { font-size: min(px-to-rem(16), $mediumScreenMaxFontPx); }
                @media screen and (max-width: $smallScreenWidthBreakpoint) { font-size: min(px-to-rem(16), $smallScreenMaxFontPx); }
                word-break: break-word;
            }
            .title.truncate {
                @include truncateLines(3);
            }

            .authorsContainer {
                padding: 5px 0px;
            }
            .authors {
                font-size: min(px-to-rem(14), $largeScreenMaxFontPx);
                @media screen and (max-width: $mediumScreenWidthBreakpoint) { font-size: min(px-to-rem(14), $mediumScreenMaxFontPx); }
                @media screen and (max-width: $smallScreenWidthBreakpoint) { font-size: min(px-to-rem(14), $smallScreenMaxFontPx); }
                word-break: break-word;
            }
            .authors.truncate {
                @include truncateLines(2);
            }
        }

        .buttonContainer {
            margin-top: 10px;
            width: auto;
            flex-direction: row;
            display: flex;
            flex-wrap: wrap;
            gap: 12px;
            align-content: center;

            button {
                img {
                    height: min(1.5rem, 35px);
                }
            }
            button:first-child {
                margin-left: 0;
                flex-grow: 1;
                max-width: max(225px, 33%);
            }
        }
    }

    .sectionDivider {
        flex-shrink: 0;
        height: 1px;
        background-color: $divider-background-color-light;
        border: none;
        margin: 0;
    }

    .releaseDate {
        color: $release-date-green-light;
        font-weight: bold;
    }

    .preOrderAutoDeliverText {
        display: none;
        text-align: center;
        padding-bottom: 10px;
        font-size: min(px-to-rem(13), $largeScreenMaxFontPx);
        @media screen and (max-width: $mediumScreenWidthBreakpoint) { font-size: min(px-to-rem(13), $mediumScreenMaxFontPx); }
        @media screen and (max-width: $smallScreenWidthBreakpoint) { font-size: min(px-to-rem(13), $smallScreenMaxFontPx); }
    }

    .releaseDateHolder {
        margin: 10px 0;
    }

    .bottomSection {
        display: flex;
        justify-content: space-between;
        position: relative;
        flex-wrap: wrap;
        white-space: nowrap;
        overflow: hidden;
        gap: 10px;
        margin-bottom: 10px;
        margin-top: 10px;
        button {
            flex: 1 1;
            flex-basis: 40%;
            color: $button-text-color-light;
            background-color: $button-background-color-light;
            text-align: center;
            .buttonLabel {
                padding: 10px 12px;
            }
        }
    }

    .addForFreeLegalText {
        text-align: center;
        padding-bottom: 10px;
        font-size: min(px-to-rem(13), $largeScreenMaxFontPx);
        @media screen and (max-width: $mediumScreenWidthBreakpoint) { font-size: min(px-to-rem(13), $mediumScreenMaxFontPx); }
        @media screen and (max-width: $smallScreenWidthBreakpoint) { font-size: min(px-to-rem(13), $smallScreenMaxFontPx); }
        .textButton {
            color: $link-text-light;
        }
    }
}

.quickViewSlideHeader.dark {
    .releaseDateHolder {
        .releaseDate {
            color: $release-date-green-dark;
        }
    }

    .sectionDivider {
        background-color: $divider-background-color-dark;
    }

    .bottomSection {
        button {
            color: $button-text-color-dark;
            background-color: $button-background-color-dark;
        }
    }
    .addForFreeLegalText {
        .textButton {
            color: $link-text-dark;
        }
    }
}
