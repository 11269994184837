@import "src/styles/themes";
@import "src/styles/functions";
@import "src/styles/global";

.sectionTitle {
    font-weight: bold;
    font-size: min(px-to-rem(16), $largeScreenMaxFontPx);
    @media screen and (max-width: $mediumScreenWidthBreakpoint) { font-size: min(px-to-rem(16), $mediumScreenMaxFontPx); }
    @media screen and (max-width: $smallScreenWidthBreakpoint) { font-size: min(px-to-rem(16), $smallScreenMaxFontPx); }
    padding: 10px 0;
}

.content {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: $tabletBreakpointW) {
        flex-direction: row;
        align-items: center;
        margin-bottom: 16px;
    }
    .row {
        display: flex;
        flex-direction: row;
        flex-grow: 2;
        align-items: center;
    }
    .coverImageWrapper {
        div {
            width: min(25vw, 90px);
            margin-right: 10px;
            img {
                max-height: min(25vw, 90px);
                max-width: min(25vw, 90px);
            }
        }
    }
    .buttonWrapper {
        margin: 10px 0;
        @media screen and (min-width: $tabletBreakpointW) {
            margin: 0;
            max-width: 240px;
            width: 100%;
        }
        button {
            width: 100%;
        }
    }
}
