@import "src/styles/themes";

.activeText {
    color: $link-text-light,
}

.dark {
    .activeText {
        color: $link-text-dark,
    }
}

.itemContainer {
    display: flex;
    flex-direction: column;
    height: 75vh;
    max-height: 75vh;
    padding-bottom: max(0px, env(safe-area-inset-bottom));

    background-color: $background-color-light;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    .chevronIconContainer {
        transform: scaleY(0.5);
    }

    .chevronIcon {
        width: 10px;
        height: 10px;
        margin: 5px auto 0px auto;
        border-top: 3px solid #626161;
        border-right: 3px solid #626161;
        transform: rotate(135deg);
    }

    .createListRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 12px;
        @media screen and (min-width: $tabletBreakpointW) {
            padding-left: 24px;
            padding-right: 24px;
        }
        label {
            margin-right: 10px;
        }
    }

    .createListRow.privacySettings {
        justify-content: flex-end;
        label {
            margin-right: 0;
            margin-left: 10px;
        }
    }

    .visibilityDescription {
        font-size: smaller;
        text-align: end;
        padding: 0px 12px;
        padding-bottom: 12px;
        @media screen and (min-width: $tabletBreakpointW) {
            padding-left: 24px;
            padding-right: 24px;
        }
    }

    .createListTextInputContainer {
        padding: 12px;
        @media screen and (min-width: $tabletBreakpointW) {
            padding-left: 24px;
            padding-right: 24px;
        }
        padding-top: 0;
    }

    .header {
        padding: 12px;
        @media screen and (min-width: $tabletBreakpointW) {
            padding-left: 24px;
            padding-right: 24px;
        }
        font-weight: bold;
    }

    label {
        font-weight: bold;
    }

    .checkbox {
        height: auto;
        flex-shrink: 0;
    }

    .textInput {
        padding: 12px;
        width: calc(100% - 24px);
        margin-top: 5px;
        background-color: $background-color-light;
        color: $primary-text-color-light;
        border: 0.1rem solid #a6a6a6;
        border-top-color: #949494;
        border-bottom-color: #d2d2d2;
        border-radius: 0rem;
    }

    .textInput:focus {
        border-color: #e77600;
        border-bottom: 0.2rem solid #e77600;
    }

    .main {
        overflow: auto;
        overscroll-behavior: contain;
        padding-left: 12px;
        padding-right: 12px;
        @media screen and (min-width: $tabletBreakpointW) {
            padding-left: 24px;
            padding-right: 24px;
        }
        height: 100%;

        .spinnerBox {
            display: flex;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
        }
    }

    button.item {
        background-color: $background-color-light;
        color: $primary-text-color-light;
        width: 100%;
        text-align: start;
        border: 1px solid $button-background-color-light;
        margin-bottom: 5px;
    }
    button.item.selectedItem {
        border: 1px solid $link-text-light;
    }


    .item {
        padding: 12px;

        .itemTitle {
            font-weight: bold;
            padding-right: 5px;
        }

        .itemMetadata {
            font-style: italic;
            font-size: smaller;
            padding-left: 5px;
            padding-right: 5px;
        }

        .itemMetadataSeparator {
            font-size: smaller;
        }
    }

    div.createAList {
        padding: 12px;
        @media screen and (min-width: $tabletBreakpointW) {
            padding-left: 24px;
            padding-right: 24px;
        }
        padding-bottom: 0;
        margin: 0;
    }

    footer.footer {
        border-top: 1px solid rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
    }

    .footerButtons {
        display: flex;
        flex-direction: row;
        padding: 12px;
        gap: 12px;
        @media screen and (min-width: $tabletBreakpointW) {
            padding-left: 24px;
            padding-right: 24px;
        }
        button {
            flex-grow: 1;
            border: none;
            padding: 10px 12px;
            margin: 0;
            text-align: center;
            white-space: nowrap;
            color: $button-text-color-light;
            background-color: $button-background-color-light;
        }
        button.ctaButton {
            color: $button-text-color-cta;
            background-color: $button-background-color-cta;
        }
        button.ctaButton.disabledButton {
            opacity: 0.35;
        }
    }
}
.itemContainer.createList {
    height: fit-content;
}

.dark.itemContainer {
    background-color: $background-color-dark;
    color: $primary-text-color-dark;
    border: 1px solid #5a5a5a;
    border-bottom: 0;

    button.item {
        background-color: $background-color-dark;
        color: $primary-text-color-dark;
        border: 1px solid $button-background-color-dark;
    }
    button.item.selectedItem {
        border: 1px solid $link-text-dark;
    }

    .textInput {
        background-color: $background-color-dark;
        color: $primary-text-color-dark;
    }

    .footer {
        button {
            color: $button-text-color-dark;
            background-color: $button-background-color-dark;
        }
        button.ctaButton {
            color: $button-text-color-cta;
            background-color: $button-background-color-cta;
        }
    }
}
