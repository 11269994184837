@import "src/styles/mixins";
@import "src/styles/themes";
@import "src/styles/functions";

.overviewSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: min(px-to-rem(12), 24px);
    margin: 5px 0;

    .label {
        display: flex;
        align-items: center;
    }

    img {
        height: min(px-to-rem(24), 30px);
        width: min(px-to-rem(24), 30px);
        vertical-align: middle;
        margin: 6px;
    }

    .value {
        font-weight: bold;
        word-wrap: break-word;
        @include truncateLines(2);
    }
}

.horizontalScroller {
    mask-image: linear-gradient(270deg, transparent, #000 2em);
    padding: 4px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 12px;
    overflow-x: auto;
    overscroll-behavior-x: contain;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    align-items: center;
    margin-bottom: 4px;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
    overscroll-behavior-y: auto; /* For Chrome */

    &::-webkit-scrollbar {
        /* For Chrome, Safari, and Opera */
        width: 0px;
        height: 0px;
        display: none;
    }

    &.disableScrolling {
        overflow: hidden;
    }

    > * {
        flex: 0 0 auto;
        margin-left: 12px;
        scroll-snap-align: center;

        &:first-child {
            margin-left: 5px;
        }

        &:last-child {
            padding-right: 2em;
        }
    }
}

.dark.horizontalScroller {
    .overviewSection {
        color: $primary-text-color-dark;
    }  
}
