@import "src/styles/themes";
@import "src/styles/mixins";

$panelCornerRadius: 20px;

.miniHeader {
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
    position: absolute;
    height: fit-content;
    width: 100%;
    padding: 0;
    z-index: 4;
    border-top-left-radius: $panelCornerRadius;
    border-top-right-radius: $panelCornerRadius;
    margin-top: -$panelCornerRadius;
    border-bottom: 1px solid $divider-background-color-light;
    box-shadow: 0px 2px 2px 0px #00000040;

    &.show {
        opacity: 1;
        .content {
            .buttonContainer {
                button {
                    visibility: visible;
                }
            }
        }
    }

    .content {
        display: flex;
        flex-direction: row;
        padding: 12px;
        @media screen and (min-width: $tabletBreakpointW) {
            padding-left: 24px;
            padding-right: 24px;
        }
        .metadataContainer {
            flex-grow: 2;
            .title {
                @include truncateLines(1);
                font-size: small;
                font-weight: bold;
                line-height: 20px;
            }
            .authors {
                @include truncateLines(1);
                font-size: small;
                line-height: 20px;
            }
        }
        .buttonContainer {
            display: flex;
            button {
                white-space: nowrap;
                align-self: center;
                margin-left: 10px;
                font-size: small;
                // Hide the button when the header isn't visible, so we don't get tap events.
                // Enable a transition here so that it doesn't just pop in/out. Should be the same duration as the opacity transition above.
                transition: visibility 0.25s ease-in-out;
                visibility: hidden;
            }
        }
    }
}

.dark.miniHeader {
    border-bottom: 1px solid #5a5a5a;
}
