@import "src/styles/themes";

.activeText {
    color: $link-text-light,
}

.dark {
    .activeText {
        color: $link-text-dark,
    }
}

.toggle {
    padding: 5px;
    padding-left: 10px;
    margin-top: 0.2em;
    width: 2.4em;
    min-width: 2.4em;
    height: 1.6em;
    fill: #8e96a1;
}

.toggle.checked {
    fill: #007dbc;
}

.toggleHandle {
    fill: #fff;
}

.hideVersionInfo {
    display: none;
}

.fittedContainer {
    display: flex;
    flex-direction: column;
    height: fit-content;
    max-height: 75vh;
    width: min(100vw, $tabletBreakpointW);
    margin-left: auto;
    margin-right: auto;
    padding-bottom: max(0px, env(safe-area-inset-bottom));
    font-size: 12px;

    background-color: $background-color-light;
    color: $primary-text-color-light;

    .parameters {
        pre {
            white-space: pre-wrap;
            overflow-wrap: break-word;
        }
    }

    section.option {
        padding-left: 12px;
    }
    section.option:hover {
        background-color: #c3ced4;
    }

    section.sectionLabel {
        padding: 12px 0;
        border-bottom: 1px solid $primary-text-color-light;
        font-weight: bold;
        margin-bottom: 6px;
    }

    header {
        display: flex;
        flex-direction: row;
        font-weight: bold;
        background-color: #007dbc;
        color: #fff;
        padding: 12px;
        align-items: center;

        .dismissButton {
            margin-left: auto;
            font-family: sans-serif;
            font-size: 24px;
            line-height: 24px;
        }
    }

    main {
        overflow: auto;
        overscroll-behavior: contain;
        padding: 12px;

        section {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }

    footer {
        display: flex;
        flex-direction: row;
        padding: 12px;
        border-top: 1px solid $primary-text-color-light;
        button {
            flex-grow: 1;
            border: none;
            padding: 10px 12px;
            margin: 0;
            text-align: center;
            white-space: nowrap;
            margin-left: 12px;
            color: white;
            background-color: #545b64;
            font-size: 1em;
        }
        button:first-child {
            margin-left: 0;
        }
    }
}

.dark.fittedContainer {
    background-color: $background-color-dark;
    color: $primary-text-color-dark;
    section.sectionLabel {
        border-bottom: 1px solid $primary-text-color-dark;
    }
    section.option:hover {
        background-color: #2d3c44;
    }
    footer {
        border-top: 1px solid $primary-text-color-dark;
    }
}
