@import "src/styles/themes";
@import "src/styles/functions";
@import "src/styles/global";

.sectionTitle {
    font-weight: bold;
    font-size: min(px-to-rem(16), $largeScreenMaxFontPx);
    @media screen and (max-width: $mediumScreenWidthBreakpoint) { font-size: min(px-to-rem(16), $mediumScreenMaxFontPx); }
    @media screen and (max-width: $smallScreenWidthBreakpoint) { font-size: min(px-to-rem(16), $smallScreenMaxFontPx); }
    padding: 10px 0;
}

.readEpisode {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: $link-text-light;
    padding: 10px 0;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .numberCircle {
        width: 138px;
        height: 138px;
        border-radius: 50%;
        background-color: #f3feff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        .header {
            font-size: 15px;
        }
        .number {
            font-size: 78px;
            line-height: 1;
            font-family: "Bookerly Display";
        }
    }
    .metadata {
        margin-top: 15px;
        margin-bottom: 10px;
        text-align: center;
        .title {
            font-size: min(px-to-rem(18), $largeScreenMaxFontPx);
        }
        .publishDate {
            font-size: min(px-to-rem(16), $largeScreenMaxFontPx);
        }
    }
}

.contentDivider {
    margin: 3px 0;
    height: 1px;
    background-color: $divider-background-color-light;
    border: none;
}

.dark {
    .readEpisode {
        color: $link-text-dark;
    }
    // TODO: Looks like none of the other dividers are actually being set to the dark mode color, oops!
    //       Will uncomment this as part of another change to fix the other dividers.
    // .contentDivider  {
    //     background-color: $divider-background-color-dark;
    // }
    .content {
        .numberCircle {
            background-color: #404040;
        }
    }
}
