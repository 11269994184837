@import "src/styles/themes";
@import "src/styles/functions";
@import "src/styles/global";

.sectionTitle {
    font-weight: bold;
    font-size: min(px-to-rem(16), $largeScreenMaxFontPx);
    @media screen and (max-width: $mediumScreenWidthBreakpoint) { font-size: min(px-to-rem(16), $mediumScreenMaxFontPx); }
    @media screen and (max-width: $smallScreenWidthBreakpoint) { font-size: min(px-to-rem(16), $smallScreenMaxFontPx); }
    padding: 10px 0;
}

.episodesList {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        padding: 16px;
        margin-bottom: 8px;
        font-size: min(px-to-rem(14), $largeScreenMaxFontPx);
        font-weight: bold;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .episodeLabel {
            flex-grow: 1;
            margin-right: 10px;
        }
    }
}

.lockedEpisode {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0;
    padding: 0;
    align-items: center;
    justify-content: end;
    svg {
        height: min(px-to-rem(17), $largeScreenMaxFontPx);
        margin-right: 5px;
    }
}

.seeAllEpisodes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: $link-text-light;
    padding: 10px 0;
}

.contentDivider {
    margin: 3px 0;
    height: 1px;
    background-color: $divider-background-color-light;
    border: none;
}

@keyframes loading-icon-animation {
    100% {
      transform: rotate(360deg);
    }
}

.loadingIconWrapper {
    margin: 0 16px;
    height: 1rem;
    width: 1rem;
    .loadingIcon {
        margin: -3px;
        display: block;
        content: '';
        border-radius: 50%;
        aspect-ratio: 1;
        border: 3px solid $link-text-light;
        border-top: 3px solid transparent;
        animation: loading-icon-animation 1.2s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
        height: 100%;
        width: 100%;
    }
}

.dark {
    .seeAllEpisodes {
        color: $link-text-dark;
    }
    .loadingIconWrapper {
        .loadingIcon {
            border: 3px solid $link-text-dark;
        }
    }
    // TODO: Looks like none of the other dividers are actually being set to the dark mode color, oops!
    //       Will uncomment this as part of another change to fix the other dividers.
    // .contentDivider  {
    //     background-color: $divider-background-color-dark;
    // }
}
