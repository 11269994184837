@import "src/styles/functions";

@font-face {
    font-family: "Amazon Ember";
    font-display: block;
    src: url(src/fonts/amazonember_rg.woff2) format("woff2"),
        url(src/fonts/amazonember_rg.woff) format("woff");
}

@font-face {
    font-family: "Amazon Ember";
    font-style: italic;
    font-display: block;
    src: url(src/fonts/amazonember_rgit.woff2) format("woff2"),
        url(src/fonts/amazonember_rgit.woff) format("woff");
}

@font-face {
    font-family: "Amazon Ember";
    font-weight: bold;
    font-display: block;
    src: url(src/fonts/amazonember_bd.woff2) format("woff2"),
        url(src/fonts/amazonember_bd.woff) format("woff");
}

@font-face {
    font-family: "Amazon Ember";
    font-style: italic;
    font-weight: bold;
    font-display: block;
    src: url(src/fonts/amazonember_bdit.woff2) format("woff2"),
        url(src/fonts/amazonember_bdit.woff) format("woff");
}

@font-face {
    font-family: "Amazon Ember";
    font-weight: 300;
    font-display: block;
    src: url(src/fonts/AmazonEmber_W_Lt.woff2) format("woff2"),
        url(src/fonts/AmazonEmber_W_Lt.woff) format("woff");
}

html {
    // Hook into Apple system font for iOS dynamic text resizing
    font: -apple-system-body;
}

$largeScreenMaxFontPx: 28px;
$mediumScreenMaxFontPx: 24px;
$smallScreenMaxFontPx: 20px;

$mediumScreenWidthBreakpoint: 400px;
$smallScreenWidthBreakpoint: 400px;

body {
    user-select: none;

    // Default fonts
    font-family: "Amazon Ember", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    font-size: min(px-to-rem(14), $largeScreenMaxFontPx);
    @media screen and (max-width: $mediumScreenWidthBreakpoint) { font-size: min(px-to-rem(14), $mediumScreenMaxFontPx); }
    @media screen and (max-width: $smallScreenWidthBreakpoint) { font-size: min(px-to-rem(14), $smallScreenMaxFontPx); }
    line-height: 1.35;

    button {
        font-family: "Amazon Ember", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
        font-size: min(px-to-rem(14), $largeScreenMaxFontPx);
        @media screen and (max-width: $mediumScreenWidthBreakpoint) { font-size: min(px-to-rem(14), $mediumScreenMaxFontPx); }
        @media screen and (max-width: $smallScreenWidthBreakpoint) { font-size: min(px-to-rem(14), $smallScreenMaxFontPx); }
        padding: unset;
        border: unset;
    }

    input {
        font-family: "Amazon Ember", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
        font-size: min(px-to-rem(14), $largeScreenMaxFontPx);
        @media screen and (max-width: $mediumScreenWidthBreakpoint) { font-size: min(px-to-rem(14), $mediumScreenMaxFontPx); }
        @media screen and (max-width: $smallScreenWidthBreakpoint) { font-size: min(px-to-rem(14), $smallScreenMaxFontPx); }
    }

    h1, h2, h3, h4, h5, h6 {
        margin: 0;
    }

    h1 {
        font-size: min(px-to-rem(18), $largeScreenMaxFontPx);
        @media screen and (max-width: $mediumScreenWidthBreakpoint) { font-size: min(px-to-rem(18), $mediumScreenMaxFontPx); }
        @media screen and (max-width: $smallScreenWidthBreakpoint) { font-size: min(px-to-rem(18), $smallScreenMaxFontPx); }
    }
    h2 {
        font-size: min(px-to-rem(17), $largeScreenMaxFontPx);
        @media screen and (max-width: $mediumScreenWidthBreakpoint) { font-size: min(px-to-rem(17), $mediumScreenMaxFontPx); }
        @media screen and (max-width: $smallScreenWidthBreakpoint) { font-size: min(px-to-rem(17), $smallScreenMaxFontPx); }
    }
    h3 {
        font-size: min(px-to-rem(16), $largeScreenMaxFontPx);
        @media screen and (max-width: $mediumScreenWidthBreakpoint) { font-size: min(px-to-rem(16), $mediumScreenMaxFontPx); }
        @media screen and (max-width: $smallScreenWidthBreakpoint) { font-size: min(px-to-rem(16), $smallScreenMaxFontPx); }
    }
    h4 {
        font-size: min(px-to-rem(15), $largeScreenMaxFontPx);
        @media screen and (max-width: $mediumScreenWidthBreakpoint) { font-size: min(px-to-rem(15), $mediumScreenMaxFontPx); }
        @media screen and (max-width: $smallScreenWidthBreakpoint) { font-size: min(px-to-rem(15), $smallScreenMaxFontPx); }
    }
    h5 {
        font-size: min(px-to-rem(14), $largeScreenMaxFontPx);
        @media screen and (max-width: $mediumScreenWidthBreakpoint) { font-size: min(px-to-rem(14), $mediumScreenMaxFontPx); }
        @media screen and (max-width: $smallScreenWidthBreakpoint) { font-size: min(px-to-rem(14), $smallScreenMaxFontPx); }
    }
    h6 {
        font-size: min(px-to-rem(13), $largeScreenMaxFontPx);
        @media screen and (max-width: $mediumScreenWidthBreakpoint) { font-size: min(px-to-rem(13), $mediumScreenMaxFontPx); }
        @media screen and (max-width: $smallScreenWidthBreakpoint) { font-size: min(px-to-rem(13), $smallScreenMaxFontPx); }
    }

    & * {
        // Disable blue box flashing around tapped elements on mobile devices
        -webkit-tap-highlight-color: transparent;
    
        // Prevent long-press interactions on iOS
        -webkit-touch-callout: none;
    
        // Prevent drag-and-drop of images/links on iOS
        -webkit-user-drag: none;

        // Prevent highlight border around selectable items
        &:focus {
            outline: none;
            -webkit-tap-highlight-color: transparent;
        }
    }
}
