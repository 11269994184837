@-webkit-keyframes rotate360 {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}
@keyframes rotate360 {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

.spinnerDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    .spinner {
        display: inline-block;
        width: 32px;
        height: 32px;
        -webkit-animation: fadeIn 0.3s ease-in forwards,rotate360 1s linear infinite;
        animation: fadeIn 0.3s ease-in forwards,rotate360 1s linear infinite;
        background: url("src/images/aui_spinner_static.png") no-repeat scroll 50% 50%;
        background-size: 32px;
    }
}
