@import "src/styles/themes";

.content {
    .contentDivider {
        margin: 3px 0;
        height: 1px;
        background-color: $divider-background-color-light;
        border: none;
    }
}

.content.dark {
    .contentDivider  {
        background-color: $divider-background-color-dark;
    }
}

.loadFailure {
    padding-top: 20px;
    text-align: center;
}
