@import "src/styles/themes";
@import "src/styles/functions";

.customerReview {

    .contributor {
        display: flex;
        flex-direction: row;
        align-items: center;

        .profileImageContainer {
            position: relative;
            background: url("src/images/default_profile.svg") no-repeat scroll 50% 50%;
            background-size: min(max(px-to-rem(37), 37px), 74px);
            height: min(max(px-to-rem(37), 37px), 74px);
            width: min(max(px-to-rem(37), 37px), 74px);
            margin-right: 10px;

            .profileImage {
                height: min(max(px-to-rem(37), 37px), 74px);
                width: min(max(px-to-rem(37), 37px), 74px);
                border-radius: 50%;
            }

            .profileImageBadgeOverlay {
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }

        .profileBadge {
            color: rgba(0, 118, 0, 1);
        }
    }

    .rating {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 8px 0;

        .programName {
            margin-left: 8px;
            color: rgba(216, 90, 0, 1);
            font-size: min(px-to-rem(13), 28px);
            line-height: min(px-to-rem(18), 42px);
            font-weight: bold;
        }
    }

    .title {
        font-weight: bold;
    }

    .origin {
        color: $secondary-text-color-light;
        font-size: min(px-to-rem(13), 28px);
        line-height: min(px-to-rem(18), 42px);
        margin: 8px 0;
    }

    margin-bottom: 12px;
}

.dark.customerReview {
    .origin {
        color: $secondary-text-color-dark;
    }
}
