@import "src/styles/themes";

.followStoryButton {
    color: $button-text-color-light;
    background: $button-background-color-light;
    border: none;
    padding: 10px 12px;
    margin: 0;
    text-align: center;
}

.dark.followStoryButton {
    color: $button-text-color-dark;
    background: $button-background-color-dark;
}
