@import "src/styles/functions";

.playPauseIcon {
    margin-right: 5px;
    svg {
        margin-top: -3px;
        height: min(1em, 16px);
        vertical-align: middle;
    }
}
