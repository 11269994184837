@import "src/styles/themes";

$horizontalMargin: 4px;
// iOS simulator doesn't seem to respect end-margins, but respects end-padding...
// Quick View cards are 100vw - (2 * (card margins = 4px) + (card content padding = 12px) = 32px), so should have 16px padding/margin on each end of the shoveler
// Calculate the card width (minus 32px for base padding levels) and divide by 2 to get the expected padding at the start/end of the card list
$endPadding: calc((100vw - min(calc(100vw - 32px), calc($tabletBreakpointW - 32px))) / 2); // This is the padding we'd get if there was actually a card present

$singleAsinModeWidth: min(100vw, $tabletBreakpointW);
$singleAsinModeEndPadding: calc((100vw - $singleAsinModeWidth) / 2);

ul.horizontalScroller {
    padding: 0;
    margin: 0;
}

.horizontalScroller {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: min(100vh, 1250px);
    overflow-x: auto;
    overscroll-behavior-x: contain;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;

    &.disableScrolling {
        overflow: hidden;
    }

    > * {
        flex: 0 0 auto;
        padding-left: $horizontalMargin;
        padding-right: $horizontalMargin;
        @media screen and (min-width: $tabletBreakpointW) {
            padding-left: calc(2 * $horizontalMargin);
            padding-right: calc(2 * $horizontalMargin);
        }
        scroll-snap-align: center;
        scroll-snap-stop: always;

        &:first-child {
            padding-left: $endPadding;
        }
        &:last-child {
            padding-right: $endPadding;
        }

    }
}

.horizontalScroller.singleAsinMode {
    overflow-x: hidden;
    > * {
        padding-left: $singleAsinModeEndPadding;
        padding-right: $singleAsinModeEndPadding;
        width: $singleAsinModeWidth;
    }
}
