.button {

    &.inProgress {
        .childContainer {
            .progressBarContainer, .progressBar {
                height: 4px;
            }
        }
    }

    .childContainer {
        padding: 10px 12px;
        position: relative;

        .progressBarContainer, .progressBar {
            height: 0;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            transition: height 0.2s ease-out;
        }

        .progressBarContainer {
            background-color: #CCCCCC;

            .progressBar {
                width: 0%;
                background-color: #EB9D3F;
            }
        }
    }
}
