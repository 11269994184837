.shareButton {
    all: unset;

    vertical-align: bottom;
    padding-left: 5px;
    padding-right: 5px;
}

// Re-add the default outline, as it was removed by the all: unset;
// .shareButton:focus-visible {
//     outline: auto;
// }
