.text {
    white-space: pre-line;
}

.ul, .ol {
    margin-top: 0;
    margin-bottom: 0;
}

.paragraph {
    display: block;
    margin-bottom: 12px;
    white-space: pre-line;
}

.emphasized {
    font-style: italic;
}

.strong {
    font-weight: bold;
}
