@import "src/styles/themes";

$topMargin: max(env(safe-area-inset-top), 10px);
$panelCornerRadius: 20px;
$containerHeight: calc(min(100vh, $tabletBreakpointH) - $topMargin);
$containerWidth: calc(min(100vw, $tabletBreakpointW) - 32px); // view-width - (2 * (4px margin + 12px content padding))

:export {
    textColorLight: $primary-text-color-light;
    textColorDark: $primary-text-color-dark;
    backgroundColorLight: $background-color-light;
    backgroundColorDark: $background-color-dark;
}

.itemContainer {
    display: flex;
    flex-direction: column;
    width: $containerWidth; 
    margin-top: $topMargin;
    height: $containerHeight;

    .topOverflowSection {
        display: flex;
        flex: 0 0;
        justify-content: space-between;
        align-items: end;
        // TODO: Maybe dismiss button should be a standalone component?
        .closeAllButton {
            // Remove default <button> styling
            all: unset;

            color: $background-color-light;
            text-align: center;
            font-size: 16px;
            margin: 10px;
            margin-top: 0;
        }

        // TODO: Maybe dismiss button should be a standalone component?
        .dismissButton {
            // Remove default <button> styling
            all: unset;

            color: $background-color-light;
            width: 24px;
            height: 24px;
            text-align: center;
            margin: 10px;
            margin-top: 0;
            line-height: 0;
        }
        // Re-add the default outline, as it was removed by the all: unset;
        // .dismissButton:focus-visible {
        //     outline: auto;
        // }
    }

    .item {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        flex-basis: 100%;
        background-color: $background-color-light;
        border-top-left-radius: $panelCornerRadius;
        border-top-right-radius: $panelCornerRadius;
        padding-top: $panelCornerRadius;
        position: relative;

        .sectionDivider {
            flex-shrink: 0;
            height: 1px;
            background-color: $divider-background-color-light;
            border: none;
            margin: 0;
        }

        .scrollingContentContainer {
            display: flex;
            flex-direction: column;
            flex: 1 1;
            flex-basis: 0;
            min-height: 0;
            overflow-y: auto;
            overscroll-behavior-y: contain;
            z-index: 2;
            padding-left: 12px;
            padding-right: 12px;
            @media screen and (min-width: $tabletBreakpointW) {
                padding-left: 24px;
                padding-right: 24px;
            }
            // mini-header is 65px, rounded corners require 20px of padding before the scrolling area to avoid showing through.
            // that would make this 45px. we remove a pixel so that we don't cross the mini-header hide boundary when scrolling to the top of the description.
            scroll-padding-top: 44px;

            &.disableScrolling {
                overflow: hidden;
            }
        }

        .detailsLinkContainer {
            flex: 0 0;
            padding: 12px;
            @media screen and (min-width: $tabletBreakpointW) {
                padding-left: 24px;
                padding-right: 24px;
            }
            background-color: #E2E2E2;
            border-top: solid $divider-background-color-light 1px;
            padding-bottom: max(12px, env(safe-area-inset-bottom));
            box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14);

            .detailsLinkButton {
                color: white;
                background: black;
                border: none;
                padding: 12px 0;
                text-align: center;
                width: 100%;
            }
        }
    }
}

.dark.itemContainer {
    .item {
        background-color: $background-color-dark;
        color: $primary-text-color-dark;
        border: 1px solid #5a5a5a;
        border-bottom: 0;
        
        .sectionDivider {
            background-color: $divider-background-color-dark;
        }

        .detailsLinkContainer {
            border-top: solid $divider-background-color-dark 1px;
            background-color: #2D2D2D;
            .detailsLinkButton {
                color: #1a1a1a;
                background: white;
            }
        }
    }
}
